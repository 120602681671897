<template>
  <div id="app">
    <div class="RIP">
      <p>Hi, we have deprecated this version of the add-in.</p>
      
      <p>You can find the new version <a target="_blank" href="https://appsource.microsoft.com/en-us/product/office/WA200006552?tab=Overview">here</a> 
      or just by searching for “Esgaia - Export for Outlook” in the add ins for outlook store.</p>
  
      <div>
        <p>The new version will now be able to:</p>
        <ul>
          <li>Export meetings from the calendar.</li>
          <li>Add attachments to your mails / meetings.</li>
          <li>Change both subject and date on mails / meetings.</li>
          <li>Create engagements.</li>
        </ul>
      </div>
    </div>

    <!-- <div class="content">
      <div class="content-header">
        <div class="padding">
          <h1>Export to Esgaia</h1>
        </div>
      </div>

      <LoginPage @login="loginFunction" v-if="page == 'loginPage' && !user" />

      <CreateEngagement v-if="page == 'createEngagementPage'" :endpoint="endpoint" :user="user" :form="form" />

      <CreateActivity v-if="page == 'createActivityPage' && user" :user="user" :endpoint="endpoint" :item="item" />

      <footer v-if="user">
        <p class="change-user" v-if="page != 'createEngagementPage'" @click="page = 'createEngagementPage'">
          Create engagement
        </p>

        <p class="change-user" v-if="page == 'createEngagementPage'" @click="page = 'createActivityPage'">
          Activity page
        </p>

        <p class="change-user" @click="logout()">
          Change user / log out
        </p>
      </footer>
    </div> -->
  </div>
</template>

<script>
import VueToast from 'vue-toast-notification';
import LoginPage from './components/LoginPage.vue';
import CreateActivity from './components/CreateActivity.vue';
import CreateEngagement from './components/CreateEngagement.vue';

const axios = require('axios');
export default {
  components: { LoginPage, CreateActivity, CreateEngagement },

  name: 'App',
  data: function() {
    return {
      localStorageApiToken: null,
      page: null,
      endpoint: {
        apikey: null,
        url: null,
      },
      user: null,
      item: {
        subject: null,
        notes: null,
        date: null,
        sender: null,
        to: null,
        cc: null,
        content: null,
        engagement_id: null,
        company: null,
        activity_type: 0,
        link_to: 'Counterparty',
        companies: [],
      },
      company: null,
      completed: null,
      form: {
        esgs: [],
        company: {},
        fundIds: [],
        created_at: new Date().toISOString().slice(0, 10),
        objective: null,
        topic: null,
        ownerId: null, // todo: check if we need this, might add owner_id in be from post
      },
      esgs: [],
      esg_issues: [],
    };
  },

  methods: {
    loginFunction: function(data) {
      this.endpoint = data.endpoint;
      this.user = data.user;
      this.page = 'createActivityPage';
    },

    checkIfAlreadyLoggedIn: function() {
      if (window.localStorage.getItem('userToken') && window.localStorage.getItem('userEndpointUrl')) {
        this.endpoint.apikey = window.localStorage.getItem('userToken');
        this.endpoint.url = window.localStorage.getItem('userEndpointUrl');
        var _this = this;
        axios
          .get(`${this.endpoint.url}/api/me`, {
            headers: {
              Authorization: `Bearer ${this.endpoint.apikey}`,
            },
          })
          .then(function(response) {
            console.log('inloggad');
            _this.page = 'createActivityPage';
            _this.user = response.data.data;
            console.log(response.data.data, 'whats in user');
          })
          .catch(function(error) {
            window.localStorage.setItem('userToken', '');
            window.localStorage.setItem('userEndpointUrl', '');
            console.log('Failed to log in');
            console.log(error);
            _this.showLoggedInPage = true;
          });
      } else {
        this.page = 'loginPage';
      }
    },
    logout: function() {
      window.localStorage.setItem('userToken', '');
      window.localStorage.setItem('userEndpointUrl', '');
      this.user = null;
      this.page = 'loginPage';
      this.endpoint = {
        apikey: null,
        url: null,
      };
    },
  },

  async mounted() {
    if(window.localStorage.getItem('userEndpointUrl')) {
      // send metric to deprecated endpoint
      const response = 
      await axios
          .get(`${window.localStorage.getItem('userEndpointUrl')}/api/plugins/outlook/deprecated`, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
            },
          })
      console.log(response);
    }
    /* remove the credentials */
    window.localStorage.setItem('userToken', '');
    window.localStorage.setItem('userEndpointUrl', '');
  
    // this.checkIfAlreadyLoggedIn();
    // this.localStorageApiToken = window.localStorage.getItem('userToken');
    // let _this = this;
    // Office.onReady((info) => {
    //   if (info.host === Office.HostType.Outlook) {
    //     console.log(Office.context.mailbox.item);
    //     _this.item.subject = Office.context.mailbox.item.subject;
    //     _this.item.sender = Office.context.mailbox.item.sender;
    //     _this.item.to = Office.context.mailbox.item.to;
    //     _this.item.cc = Office.context.mailbox.item.cc;
    //     _this.item.date = Office.context.mailbox.item.dateTimeCreated;
    //     Office.context.mailbox.item.body.getAsync(Office.CoercionType.Html, { asyncContext: 'This is passed to the callback' }, function callback(result) {
    //       _this.item.content = result.value;
    //       console.log(result.value);
    //     });
    //   }
    // });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
* {
  font-family: 'Lato', sans-serif;
}

.RIP {
font-size: 16px;
}

.multiselect__option--highlight {
  background: #38ae90;
}

.multiselect__option--highlight:after {
  background: #38ae90;
  content: 'Press enter to select';
}

.multiselect__option--selected:after {
  content: 'Selected';
}

.multiselect__option--selected:hover:after {
  content: 'Press enter to remove';
}

.multiselect__tag {
  background: #38ae90 !important;
  color: #ffffff !important;
}

h1,
h2,
h3 {
  font-family: 'Montserrat', sans-serif;
  color: #404040;
}

h1 {
  color: #fff;
  font-size: 21px;
  text-align: center;
}

h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  color: #404040;
  margin-bottom: -4px;
  margin-top: 12px;
}

.sign-in-heading {
  text-align: center;
}

.logged-in-as {
  display: flex;
  margin-bottom: 18px;
  margin-top: 3px;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
}

.text-center {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  width: 100%;
}

.data-sent {
  text-align: center;
}

.data-sent-link {
  text-align: center;
  font-size: 12px;
}

.input-radio-container input[type='radio'] + label {
  display: inline-block;
  margin-top: -5px;
  vertical-align: middle;
}

label {
  color: #606060;
  font-size: 12px;
}

.notes-label {
  display: flex;
  font-size: 12px;
  color: #a3a3a3;
  font-weight: bold;
  letter-spacing: 0.04em;
  margin-top: 24px;
}

input[type='radio'] {
  margin-top: 10px;
}

.apikey {
  font-size: 14px;
}

.textarea-api {
  width: calc(100% - 12px);
  border-radius: 10px;
  font-size: 12px;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 4px;
  max-height: 40px;
}

.textarea-item,
.textarea-objective {
  width: calc(100% - 26px);
  height: 46px;
  margin-bottom: 10px;
  margin-top: 4px;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.textarea-item {
  margin-bottom: 21px;
}

.textarea-objective {
  margin-top: 0px;
  margin-bottom: -3px;
}

.input-radio-container input[type='radio'] + label {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}

input.input {
  width: 93%;
  height: 30px;
  border-radius: 0px;
  border: 0;
  border-bottom: 2px solid #38ae90;
  margin-bottom: 15px;
  padding: 0 10px;
}

.loading-box-activity,
.loading-box-engagement {
  display: block;
  height: 80px;
  width: 80px;
  margin-left: 35%;
}

.loading-box-activity {
  margin-top: 120px;
}

.loading-box-engagement {
  margin-top: 180px;
}

p.small {
  margin-top: 8px;
  font-size: 12px;
}

.multiselect-engagements {
  margin-top: 8px;
  margin-bottom: 12px;
}

select {
  max-width: 100%;
  min-width: 100%;
  height: 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 0 10px;
}

.btn-primary {
  display: inline-block;
  background-color: #38ae90;
  padding: 10px 20px;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 0px;
  border: 0px;
  transition: all 0.3s ease;
  width: 100%;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #60bea6;
}

.btn-send {
  margin-top: 40px;
}

.btn-create {
  margin-top: 30px;
}

.content-header {
  background: #38ae90;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  overflow: hidden;
}

.create-engagement-header {
  font-size: 16px;
  margin-bottom: 16px;
}

.create-engagement-link-container {
  font-size: 15px;
  display: flex;
  position: absolute;
  margin-top: 475px;
  color: #646464;
  padding-left: 7px;
}

.create-engagement-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.box {
  padding-bottom: 20px;
}

.single-box {
  margin-top: 24px;
}

input.date-form {
  border-radius: 5px;
  border: 1px solid #cccccc;
  outline: none;
  box-shadow: none !important;
}

.date-form {
  display: block;
  width: 247px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.eng-label {
  display: flex;
  padding-bottom: 4px;
  font-size: 12px;
  color: #a3a3a3;
  font-weight: bold;
  letter-spacing: 0.04em;
}

.required::after {
  content: '*';
}

.optional::after {
  content: ' (optional)';
  padding-left: 3px;
}

.eng-input {
  display: flex;
  flex-direction: row;
  width: 97%;
  color: #a3a3a3;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 12px;
  color: #646464;
  padding-left: 15px;
  z-index: 10;
  margin-top: 25px;
  background-color: #eaeaea;
}

.change-user:hover {
  cursor: pointer;
  text-decoration: underline;
}

.content-main {
  background: #fff;
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
}

.padding {
  padding: 15px;
  padding-bottom: 85px;
}

.single-workspace {
  padding: 5px 20px;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  margin-top: 5px;
  &:hover {
    background: #fbfbfb;
    cursor: pointer;
  }
}
.error-message {
  color: #880000;
  margin-top: 10px;
  font-size: 13px;
}

.single-error-message {
  color: #880000;
  margin-top: 4px;
  font-size: 13px;
}
</style>
