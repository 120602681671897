<template>

	<div class="spinner" v-else>
		<div class="lds-ring"><div></div><div></div><div></div><div></div></div>
		<p><slot></slot></p>
	</div>

</template>

<script>
export default {
	props: ['size'],
	methods: {
		testFunction: function() {
			console.log('this is a test function')
		},
	},
	mounted() {
		console.log('Component mounted.')
	}
}
</script>

<style lang="scss">

.theme-list-component .lds-ring {
	display: inline-block;
	position: relative;
	width: 80px !important;
	height: 80px !important;
	div {
		width: 60px !important;
		height: 60px !important;
		border-width: 8px !important;
	}
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	background-color: transparent;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #18bc9d;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #18bc9d transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


</style>
