<template>
  <div id="app">
    <div class="content">
      <div class="content-main" v-if="user">
        <div class="padding">
          <p class="logged-in-as">"Logged in as {{ user.name }} on {{ user.organization }}"</p>

          <hr />

          <h4>Subject</h4>
          <p class="small">{{ item.subject }}</p>
          <h4>From</h4>
          <p class="small">{{ item.sender.displayName }} {{ `<${item.sender.emailAddress}>` }}</p>

          <hr />
          <p class="small"></p>

          <div v-if="completed">
            <DataSentPage :user="user" :endpoint="endpoint" :item="item" :completed="completed" type="activity" />
          </div>
          <div v-else-if="loading" class="loading-box-activity">
            <spinner></spinner>
          </div>

          <div v-else>
            <label class="notes-label optional">Notes</label>
            <textarea class="textarea-item" v-model="item.notes"></textarea>
            <div style="margin-bottom: 5px">
              <label class="esgaia-checkbox sub-label">
                <input type="checkbox" id="link-to" v-model="linkTo" />
                <span class="checkmark"></span>
              </label>
              <label for="link-to" class="sub-label link-to">Activity in thematic engagement</label>
            </div>

            <div v-if="!linkTo">
              <SearchCompany :endpoint="endpoint" v-model="company" />
              <multiselect
                class="multiselect-engagements"
                :disabled="!company"
                v-model="multiselectEng"
                :options="engagements"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                :max-height="200"
                track-by="id"
                label="name"
                placeholder="Search your engagements"
                :multiple="true"
              ></multiselect>
            </div>
            <div v-else>
              <multiselect
                data-cy="select-engagement"
                name="select-engagement"
                v-model="multiselectEng"
                :options="themeEngagements"
                @input="getThemeCompanies"
                label="title"
                track-by="id"
                placeholder="Search your engagements"
              ></multiselect>
              <p class="error-message" v-if="error && error.engagement_required">{{ error.engagement_required }}</p>

              <multiselect
                :disabled="!multiselectEng"
                class="multiselect-engagements"
                placeholder="Search counterparty"
                v-model="item.companies"
                :options="themeCompanies"
                :multiple="true"
                track-by="id"
                label="name"
              ></multiselect>
            </div>
            <div class="input-radio-container">
              <input type="radio" id="activity_type_0" v-model="item.activity_type" name="activity_type" value="0" />
              <label class="label-radio" for="activity_type_0">Internal activity</label>
              <br />
              <input type="radio" id="activity_type_1" v-model="item.activity_type" name="activity_type" value="1" />
              <label class="label-radio" for="activity_type_1">External Activity</label>
            </div>

            <button class="btn-send btn-primary" @click="postForm()">
              Send to esgaia
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataSentPage from './DataSentPage.vue';
import SearchCompany from './SearchCompany.vue';
import VueToast from 'vue-toast-notification';

const axios = require('axios');

export default {
  components: { DataSentPage, SearchCompany },
  name: 'App',
  props: ['user', 'endpoint', 'item'],
  data: function() {
    return {
      multiselectEng: null,
      company: null,
      page: null,
      error: null,
      loading: false,
      completed: null,
      engagements: [],
      selectedEngagement: null,
      linkTo: false,
      themeEngagements: [],
      themeCompanies: [],
      companies: [],
    };
  },
  watch: {
    linkTo: function(newVal, oldVal) {
      this.company = null;
      this.multiselectEng = null;
      this.companies = [];
      this.error = null;
      if (newVal) {
        this.item.link_to = 'Engagement';
        this.getThemeEngagements();
      } else {
        this.item.link_to = 'Counterparty';
      }
    },
    selectedEngagement: function(val) {
      this.item.engagement_id = val;
    },
    multiselectEng: function(val) {
      if (!val) {
        this.selectedEngagement = null;
        this.companies = [];
        this.themeCompanies = [];
      } else {
        if (this.item.link_to == 'Counterparty') {
          this.selectedEngagement = val.map((x) => x.id);
        } else {
          this.selectedEngagement = val.id;
        }
      }
    },

    company: function(company) {
      this.selectedEngagement = null;
      this.multiselectEng = null;
      if (!company) {
        this.item.company = null;
      } else {
        this.item.company = company.id;
        this.getEngagements(company);
      }
    },
  },
  methods: {
    getThemeCompanies: function() {
      let _this = this;
      axios
        .get(`${this.endpoint.url}/api/engagements/${_this.multiselectEng.id}/subengagements`, { /* normal api route */
          headers: {
            Authorization: 'Bearer ' + _this.endpoint.apikey,
          },
        })
        .then(function(response) {
          _this.themeCompanies = response.data.data.map((eng) => eng.company);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getThemeEngagements: function() {
      let _this = this;
      axios
        .get(`${this.endpoint.url}/api/engagements/themes`, { /* normal api route */
          headers: {
            Authorization: 'Bearer ' + _this.endpoint.apikey,
          },
        })
        .then(function(response) {
          _this.themeEngagements = response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    postForm: function() {
      let _this = this;
      _this.loading = true;
      axios
        .post(`${this.endpoint.url}/api/plugins/old/outlook/post`, _this.item, { /* TEMPORARY API ROUTE */
          headers: {
            Authorization: 'Bearer ' + _this.endpoint.apikey,
          },
        })
        .then(function(response) {
          _this.loading = false;
          _this.item.notes = null;
          _this.completed = response.data.data;
          plausible('Send email to Esgaia with Outlook Addin');
        })
        .catch(function(error) {
          _this.loading = false;
          _this.error = error.response.data;
        });
    },
    getEngagements: function(company) {
      let _this = this;
      axios
        .get(`${this.endpoint.url}/api/company/${company.id}/engagements`, { /* normal api route */
          headers: {
            Authorization: 'Bearer ' + this.endpoint.apikey,
          },
        })
        .then(function(response) {
          _this.engagements = response.data.data;
        });
    },
  },
  mounted() {},
};
</script>
