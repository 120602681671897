<template>
  <div class="select" data-cy="company-select">
    <multiselect
      data-cy="search-company"
      :options="companies"
      v-model="company"
      @search-change="searchCompany"
      label="name"
      track-by="id"
      @input="selectCompany(company)"
      placeholder="Search counterparty"
      ><template slot="noOptions"></template
    ></multiselect>
    <p class="form-error-message" v-if="this.errors.company">
      {{ this.errors.company[0] }}
    </p>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  props: ["value", "endpoint"],

  data: function() {
    return {
      company: null,
      companies: [],
      multiselect: {
        companies: "",
      },
      errors: [],
    };
  },
  methods: {
    searchCompany: function(query) {
      this.multiselect.companies = query;
      if (query.length < 2) {
        this.companies = [this.company];
        return false;
      } else {
        let _this = this;
        setTimeout(function() {
          if (_this.multiselect.companies == query) {
            _this.getSearchCompany(query);
          }
        }, 400);
      }
    },
    getSearchCompany(query) {
      let _this = this;
      axios
        .get(`${this.endpoint.url}/api/company/search/${query}`, {/* normal api */
          headers: {
            Authorization: `Bearer ${_this.endpoint.apikey}`,
          },
        })

        .then(function(response) {
          if (response.data.data) {
            _this.companies = Object.values(response.data.data);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    selectCompany(company) {
      this.$emit("input", company);
    },
  },
  mounted() {
    this.getSearchCompany("am");
    this.company = null;
  },
};
</script>
