<template>
  <div id="app">
    <div class="content">
      <div class="content-main" v-if="page == 'login'">
        <div class="padding">
          <h4 class="sign-in-heading">Sign in to your account</h4>
          <br />
          <label for="url" v-if="loginForm.url.length > 0">URL</label><br />
          <input
            class="input"
            type="text"
            name="url"
            v-model="loginForm.url"
            placeholder="URL"
            v-on:keyup.enter="postLoginForm()"
          />
          <br />
          <label for="apikey" v-if="loginForm.apikey.length > 0">API key</label
          ><br />
          <input
            class="input"
            type="apikey"
            name="apikey"
            v-model="loginForm.apikey"
            placeholder="API key"
            v-on:keyup.enter="postLoginForm()"
          />
          <br />
          <div class="apikey-info-container">
            <p class="apikey-info"></p>
          </div>

          <p class="small" v-if="error.type == 'loginform'">
            Failed to log in
          </p>
          <button
            type="submit"
            class="btn btn-primary"
            @click="postLoginForm()"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueToast from "vue-toast-notification";

const axios = require("axios");

export default {
  name: "App",
  data: function() {
    return {
      multiselectEng: null,
      page: "login",
      user: {
        name: null,
      },
      authUrl: "https://auth.esgaia.com/api/auth/login",
      userToken: null,
      endpoint: {
        apikey: null,
        url: null,
      },
      error: {
        type: null,
        message: null,
      },

      loading: false,
      loginForm: {
        url: "",
        apikey: "",
      },
      exampleArray: ["Example 1", "Example 2", "Example 3"],
    };
  },

  watch: {
    loginFormString: function(val) {
      this.error = {
        type: null,
        message: null,
      };
    },
  },
  computed: {
    loginFormString: function() {
      return JSON.stringify(this.loginForm.email + this.loginForm.password);
    },
  },
  methods: {
    postLoginForm: function() {
      /*
      eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNzhkYmU0ZGM1OTVkMDUyZmUzMDA0YjliNzlhMmYzOGQyZjExZjUyM2IzY2FiMjZhZjQ1NjY2ODkxYzYyZDAzZDRkOWNjN2ZmYjBlZmNmMDMiLCJpYXQiOjE2NzA4NDc2NTguNTY3OTkyLCJuYmYiOjE2NzA4NDc2NTguNTY3OTk0LCJleHAiOjE3MDIzODM2NTguNTYwMDgyLCJzdWIiOiIxMTciLCJzY29wZXMiOltdfQ.nTDrrnelbXUXhB7Ao12Jodq955kUUZ_tFAIZaiqdWreoltlVNrpXyqGU5RPDPIMaQJpXv4dZ15nKy2vgDMRAy5YCrz1no4JPUsUM4HSHo_Luq_uogKbHpVWIDQWO82GCvCQKRSoC1ylJWVkkb95ZzQ8Ekko0tEmNSxH1HC0wBNpM_Jf5vj8v-2ZX8m8NtKLM4ppytwXUSKbgvYNoqveCo3pkXyN1IzZt17Qdj0VH8TnDtFbijUoWm-s2d_xrzpgvmbmhrKR1YVyUZrwJ15ZejImYfSJC74zVpIE0YeE-Q6SIv8zhy84Hi8sepUvEOizmexQhI63nh6cgTDINLah0VnTQixm_XXCgKUajt_4Gw4KWjpif1EHBpUx3A_bTuajFRuAo-EXhvdrO1AgotLEZXytB0VmtNBTVIJA7ZGPzlrC_M1K1XarfXWhEMt7JY3EmrCJrPgs2k1MHMsVnPD-0x3HXcDIxNflOYmi80AUGP4CAaGs_U3zKED1qBpJz9rqyI_cLct9sp-vnFUNMHhfwIJN3dCm2VVEUXv_UFcKI_IZhP7YnDsMDDmFGbbvUA7J_OrTDrqDh4nHDODR_A9AbuavwKL0c8Tt0k8DKkaTryoZhF35pHspERr-z14YMLXcHOLX5xPaMHxzslS6HnLa9wRXTfbruRhyISsbc9BgTzj8
      */
      var _this = this;
			//if url ends with a slash, remove it
			if (this.loginForm.url.endsWith("/")) {
				this.loginForm.url = this.loginForm.url.slice(0, -1);
			}
      axios
        .get(`${this.loginForm.url}/api/me`, { /* normal api route */
          headers: {
            "content-type": "text/json",
            Authorization: `Bearer ${_this.loginForm.apikey}`,
          },
        })
        .then(function(response) {
          _this.endpoint.apikey = _this.loginForm.apikey;
          _this.endpoint.url = _this.loginForm.url;
          _this.user = response.data.data;
          _this.page = "index";
          window.localStorage.setItem("userToken", _this.endpoint.apikey);
          window.localStorage.setItem("userEndpointUrl", _this.endpoint.url);

          var returnData = {
            endpoint: _this.endpoint,
            user: _this.user,
          };
          _this.$emit("login", returnData);
        })
        .catch(function(error) {
          console.log("esgaia error on post loginform ");
          console.log(error);
          _this.error.type = "loginform";
        });
    },

    getUser() {
      let _this = this;
      console.log("get user");
      axios
        .get(`${this.endpoint.url}/api/me`, { /* normal api route */
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("userToken"),
          },
        })
        .then(function(response) {
          if (response.data.data.id > 0) {
            _this.user = response.data.data;
            _this.page = "index";
            _this.getEngagements();
          }
        });
    },
  },
  mounted() {
    let _this = this;
    if (!window.localStorage.getItem("userToken")) {
      window.localStorage.setItem("userToken", "");
    }

    if (!window.localStorage.getItem("userEndpointUrl")) {
      window.localStorage.setItem("userEndpointUrl", "");
    }

    if (
      window.localStorage.getItem("userToken").length > 10 &&
      window.localStorage.getItem("userEndpointUrl").length > 5
    ) {
      _this.endpoint.url = window.localStorage.getItem("userEndpointUrl");
      _this.getUser();
    }
  },
};
</script>
