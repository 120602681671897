import Vue from "vue";
import App from "./App.vue";
import "vue-toast-notification/dist/theme-sugar.css";
import Multiselect from "vue-multiselect";

Vue.config.productionTip = false;
Vue.component("spinner", require("./components/SpinnerComponent.vue").default);
Vue.component("multiselect", Multiselect);

window.Office.initialize = () => {
  window.Office.onReady().then(function() {
    new Vue({
      render: (h) => h(App),
    }).$mount("#app");
  });
};

window.Office.actions.associate("sendMeetingToEsgaia", sendMeetingToEsgaia);

async function sendMeetingToEsgaia() {
  const mailboxItem = window.Office.context.mailbox.item;

  if (
    mailboxItem.itemType === window.Office.MailboxEnums.ItemType.Appointment
  ) {
    console.log("sendMeetingToEsgaia");
    // // check if user is logged in
    // if (
    //   window.localStorage.getItem("userToken") &&
    //   window.localStorage.getItem("userEndpointUrl")
    // ) {
    //   console.log("check api token against endpointUrl");
    //   // TODO: check api/me so to ensure api token
    //   const apiToken = window.localStorage.getItem("userToken");
    //   const endpointUrl = window.localStorage.getItem("userEndpointUrl");

    //   const attendees = [
    //     ...mailboxItem.requiredAttendees,
    //     ...mailboxItem.optionalAttendees,
    //   ];
    //   mailboxItem.body.getAsync(
    //     "text",
    //     { asyncContext: "This is passed to the callback" },
    //     function callback(result) {
    //       // Extract the meeting note in text format
    //       if (result.status === "succeeded") {
    //         const appointmentData = {
    //           subject: mailboxItem.subject,
    //           description: result.value,
    //           start: mailboxItem.start,
    //           end: mailboxItem.end,
    //           attendees,
    //         };

    //         // Send the data to Esgaia
    //         fetch(`${endpointUrl}/api/plugins/outlook/calendar`, {
    //           method: "POST",
    //           headers: {
    //             Authorization: "Bearer " + apiToken,
    //             "Content-Type": "application/json",
    //           },
    //           body: JSON.stringify(appointmentData),
    //         })
    //           .then((response) => response.json())
    //           .then((data) => {
    //             if (data.status === "success") {
    //               window.localStorage.setItem(
    //                 "meetingUrl",
    //                 `${endpointUrl}/action/edit/${data.data.id}`
    //               );
    //               window.Office.context.ui.displayDialogAsync(
    //                 "https://localhost:3000/success.html",
    //                 {
    //                   height: 20,
    //                   width: 30,
    //                   displayInIframe: true,
    //                 }
    //               );
    //             }
    //           });
    //       }
    //     }
    //   );
    // } else {
    //   // If userToken and userEndpointUrl is not set open iframe with login page
    //   console.error("missing userToken and userEndpointUrl");
    //   window.Office.context.ui.displayDialogAsync(
    //     "https://localhost:3000/index.html",
    //     {
    //       displayInIframe: true,
    //       width: 80,
    //       hight: 80,
    //     }
    //   );
    // }
  }
}
