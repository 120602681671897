<template>
  <div id="app">
    <div class="content">
      <div class="content-main">
        <div class="padding">
          <div class="text-center">
            <h3 class="data-sent">Data sent to esgaia!</h3>
            <a
              class="data-sent-link"
              :href="`${this.endpoint.url}${url}`"
              style="color: #7a7a7a; justify-content: center; font-size: 16px;"
              target="_blank"
              >Your {{ type }} can be found here on esgaia</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueToast from "vue-toast-notification";

const axios = require("axios");

export default {
  name: "App",
  props: ["user", "endpoint", "item", "completed", "type"],
  data: function() {
    return {
      multiselectEng: null,
      page: "login",
      error: {
        type: null,
        message: null,
      },
      loading: false,
    };
  },
  computed: {
    url: function() {
      if (this.type === "activity") {
        return `/action/edit/${this.completed.id}`;
      } else {
        return `/engagement/${this.completed.id}/edit`;
      }
    },
  },
};
</script>
